import {reduceGetWithoutState} from '../../../../utils/reducerUtil';
import {
    GET_COMPANY_DETAILS_PENDING,
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_FAIL, REMOVE_COMPANY_USER_SUCCESS,
} from '../types'

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COMPANY_DETAILS_PENDING:
        case GET_COMPANY_DETAILS_SUCCESS:
        case GET_COMPANY_DETAILS_FAIL:
            return reduceGetWithoutState(action.type, action);
        case REMOVE_COMPANY_USER_SUCCESS:
            return {
                ...state,
                data: {users: state.data.users.filter(user => user.id !== action.payload)}
            };
        default:
            return state;
    }
};
