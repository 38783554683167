import {
    GET_INTERNAL_TASK_COMMENT_PENDING,
    GET_INTERNAL_TASK_COMMENT_SUCCESS,
    GET_INTERNAL_TASK_COMMENT_FAIL,
    GET_EXTERNAL_TASK_COMMENT_PENDING,
    GET_EXTERNAL_TASK_COMMENT_SUCCESS,
    GET_EXTERNAL_TASK_COMMENT_FAIL,
    PUT_EXTERNAL_TASK_COMMENT_SUCCESS,
} from './types'
import {reduceGetWithoutState} from '../../../utils/reducerUtil'

export const reducer = (state = [], action) => {
    switch (action.type) {
        case GET_INTERNAL_TASK_COMMENT_PENDING:
        case GET_INTERNAL_TASK_COMMENT_SUCCESS:
        case GET_INTERNAL_TASK_COMMENT_FAIL:
        case GET_EXTERNAL_TASK_COMMENT_PENDING:
        case GET_EXTERNAL_TASK_COMMENT_SUCCESS:
        case GET_EXTERNAL_TASK_COMMENT_FAIL:
            if(action.type.includes('INTERNAL')) {
                return {
                    ...state,
                    internal: reduceGetWithoutState(action.type, action)
                };
            }
            if(action.type.includes('EXTERNAL')) {
                return {
                    ...state,
                    external: reduceGetWithoutState(action.type, action)
                };
            }
            break;
        case PUT_EXTERNAL_TASK_COMMENT_SUCCESS:
            state.external.data.push(action.payload.data);
            return state;
        default:
            return state;
    }
};
