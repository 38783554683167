import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import * as actions from '../actions';
import { Info } from '../component/Info';

const mapStateToProps = state => ({
    info: state.get('info')
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export const InfoContainer = connect(mapStateToProps, mapDispatchToProps)(Info);
