export const menu = {
    menu_all_tasks: 'All tasks',
    menu_my_tasks: 'My tasks',
    menu_add_task: 'Add task',
    menu_users: 'Users',
    menu_company_users: 'Company accounts',
    menu_companies: 'Companies',
    menu_statistics: 'Statistics',
    menu_logout: 'Logout',
}
