import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Flag} from 'semantic-ui-react';
import {I18nRepositoryMemory} from '../i18n/I18nRepositoryMemory';

export function LanguageSelector(props) {
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const languagesList = I18nRepositoryMemory.getAllLanguages();

    const renderFlags = () => {
        return languagesList.map(ele => (
            <Flag key={ele._code} name={ele._code} onClick={() => changeLanguage(ele._code)}/>
        ))
    }

    const renderCodes = () => {
        return (
            <Fragment>
                {/*{t('language')}&nbsp;*/}
                {languagesList.map((ele, i) => [
                    i > 0 && ' | ',
                    <a key={ele._code} onClick={() => changeLanguage(ele._code)}>{ele._name}</a>
                ])}
            </Fragment>
        )
    }

    return props.flags ? renderFlags() : renderCodes();
}