export const task = {
    view_closed: 'Browse closed',
    task_list_filter_from: 'From',
    task_list_filter_to: 'To',
    date_input_search: 'Search',
    task_list_filter_reset: 'reset',
    task_title: 'Title',
    task_company_name: 'Company',
    task_status: 'Status',
    task_assigned_user: 'Assigned user',
    task_created_date: 'Created date',
    task_planned_date: 'Planned date',

    task_details_header: 'Details',
    task_last_modification: 'Last modification',
    task_download_all: 'Download all',
    task_comments: 'Comments',
    task_employee_comment: 'Employee comment (internal)',
    task_add_comment: 'Add comment',
    task_edit_history: 'Edit history',
    task_detail_attribute: 'Detail attribute',
    task_attribute_before: 'Before',
    task_attribute_after: 'After',
    task_change_date: 'Change date',
    task_add: 'Add task',
    task_edit: 'Edit task',
    task_created_by: 'Created by',
    task_details_planned_date: 'Planned arrival date',
    task_description: 'Description',
    task_status_action_take: 'Take',
    task_status_action_close: 'Close',
    task_status_action_to_do: 'Undo',
    task_assign_button: 'Assign to me',
    task_status_change: 'Change status',

    task_files: 'Files',
    task_saved: 'Task saved',
    task_validation_title_required: 'Task title is required',
    task_validation_title_constrain: 'Too many characters',
    task_validation_file_constrain: 'One of attached file is too big',
    task_validation_files_constrain: 'Selected files are too big, currently: ',

    color_legend_show: 'Show legend',
    color_legend_hide: 'Hide legend',
    color_legend_INTERNAL: 'Internal comment',
    color_legend_EXTERNAL: 'External comment',
    color_legend_NEW_FILES: 'New files',
}
