import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';
import { reducer as task } from '../ui/component/task/index';
import { reducer as user } from '../ui/component/user/index';
import { reducer as company } from '../ui/component/company/index';
import { reducer as auth } from '../ui/component/auth/authReducer';
import { reducer as info } from '../ui/component/info/infoReducer';

export const mainReducer = combineReducers({
    form,
    auth,
    task,
    user,
    company,
    info,
});
