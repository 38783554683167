export class Language {
  _code;
  _name;

  constructor(code, name) {
    this._code = code;
    this._name = name;
  }

  getCode() {
    return this._code;
  }

  getName() {
    return this._name;
  }

  equals(language) {
    if (!(language instanceof Language)) return false;

    return (
      this.getCode() === language.getCode()
      && this.getName() === language.getName()
    );
  }

  clone() {
    return new Language(
      this.getCode(),
      this.getName(),
    );
  }

  static builder() {
    /* eslint-disable-next-line no-use-before-define */
    return new LanguageBuilder();
  }
}

class LanguageBuilder {
  _code;
  _name;

  withCode(code) {
    this._code = code;
    return this;
  }

  withName(name) {
    this._name = name;
    return this;
  }

  build() {
    return new Language(this._code, this._name);
  }
}
