export const GET_COMPANIES_PENDING = 'GET_COMPANIES_PENDING';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';

export const GET_COMPANY_DETAILS_PENDING = 'GET_COMPANY_DETAILS_PENDING';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAIL = 'GET_COMPANY_DETAILS_FAIL';

export const ADD_COMPANY_PENDING = 'ADD_COMPANY_PENDING';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';

export const REMOVE_COMPANY_USER_PENDING = 'REMOVE_COMPANY_USER_PENDING';
export const REMOVE_COMPANY_USER_SUCCESS = 'REMOVE_COMPANY_USER_SUCCESS';
export const REMOVE_COMPANY_USER_FAIL = 'REMOVE_COMPANY_USER_FAIL';
