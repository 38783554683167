import { combineReducers } from 'redux';
import { reducer as taskList } from './taskListReducer';
import { reducer as taskSelected } from './taskSelectedReducer';
import { reducer as taskDetails } from './taskDetailsReducer';
import { reducer as taskComments } from './taskCommentsReducer';
import { reducer as taskHistory } from './taskHistoryReducer';

export const reducer = combineReducers({
    taskList,
    taskSelected,
    taskDetails,
    taskComments,
    taskHistory,
});
