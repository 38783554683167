import {
    GET_USERS_PENDING,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    REMOVE_USER_SUCCESS
} from './types'

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USERS_PENDING:
            return {
                ...state,
                loading: true
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: action.payload
            };
        case GET_USERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case REMOVE_USER_SUCCESS:
            return {
                ...state,
                list: state.list.filter(user => user.id !== action.payload)
            };
        default:
            return state;
    }
};
