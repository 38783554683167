import { login } from './login';
import { menu } from './menu';
import { task } from './task';
import { table } from './table';
import { user } from './user';
import { company } from './company';

export const ru = {
    language: 'Язык',
    action: 'Действие',
    no_files: 'Файлов нет',
    cancel: 'отменить',
    save: 'Сохранить',
    saving: 'Сохранение',
    edit: 'редактировать',
    client: 'клиент',
    employee: 'сотрудник',
    admin: 'администратор',
    saved_changes: 'Изменения сохранены',
    to_do: 'делать',
    in_progress: 'в процессе',
    done: 'законченный',
    ...login,
    ...menu,
    ...task,
    ...table,
    ...user,
    ...company,
}
