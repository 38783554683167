export const menu = {
    menu_all_tasks: 'Wszystkie zlecenia',
    menu_my_tasks: 'Moje zlecenia',
    menu_add_task: 'Dodaj zlecenie',
    menu_users: 'Użytkownicy',
    menu_company_users: 'Konta firmowe',
    menu_companies: 'Firmy',
    menu_statistics: 'Statystyki',
    menu_logout: 'Wyloguj',
}
