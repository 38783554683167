import { LocalStorage } from './LocalStorage';

export class AuthRepository {
  static LOCAL_STORAGE_KEY_TOKEN = 'accessToken';

  static readAccessToken() {
    return LocalStorage.read(AuthRepository.LOCAL_STORAGE_KEY_TOKEN);
  }

  static saveAccessToken(accessToken) {
    LocalStorage.create(AuthRepository.LOCAL_STORAGE_KEY_TOKEN, accessToken);
  }
}
