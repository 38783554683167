import {
    GET_TASKS_PENDING,
    GET_TASKS_SUCCESS,
    GET_TASKS_FAIL,
    GET_MY_TASKS,
    ASSIGN_TASK_SUCCESS,
    CHANGE_STATUS_SUCCESS, CLEAR_NOTIFICATIONS_SUCCESS
} from './types'

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_TASKS_PENDING:
            return {
                ...state,
                loading: true
            };
        case GET_TASKS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: action.payload.content,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements
            };
        case GET_TASKS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_MY_TASKS:
            return Object.assign({}, state, {
                myTasks: action.payload
            });
        case ASSIGN_TASK_SUCCESS:
        case CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                list: replaceItem(state.list, action.payload.data)
            };
        case CLEAR_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                list: clearNotification(state.list, action.payload)
            };
        default:
            return state;
    }
};

const clearNotification = (list = [], taskId) => {
    const indexOfItemInArray = list.findIndex(q => q.id === taskId);
    if (indexOfItemInArray > -1) {
        list[indexOfItemInArray].notification = false;
    }
    return list;
};

const replaceItem = (list = [], newItem) => {
    const indexOfItemInArray = list.findIndex(q => q.id === newItem.id);

    if (indexOfItemInArray > -1) {
        list[indexOfItemInArray] = newItem;
    }
    return list;
};
