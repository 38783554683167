import { login } from './login';
import { menu } from './menu';
import { task } from './task';
import { table } from './table';
import { user } from './user';
import { company } from './company';
import { statistics } from './statistics';

export const gb = {
    language: 'Language',
    action: 'Action',
    no_files: 'No files',
    cancel: 'Cancel',
    save: 'Save',
    saving: 'Saving',
    edit: 'Edit',
    client: 'Client',
    employee: 'Employee',
    admin: 'Admin',
    saved_changes: 'Changes saved',
    to_do: 'To do',
    in_progress: 'In progress',
    done: 'Done',
    ...login,
    ...menu,
    ...task,
    ...table,
    ...user,
    ...company,
    ...statistics,
}
