import { Map, List } from 'immutable';
import { Language } from './Language';

export class LanguageMapper {
  static languageFromData(data) {
    return Language.builder()
      .withCode(data.code)
      .withName(data.name)
      .build();
  }

  static dataFromLanguage(language) {
    return {
      code: language.getCode(),
      name: language.getName(),
    };
  }

  static immutableDataFromLanguage(language) {
    return Map(this.dataFromLanguage(language));
  }

  static languagesListFromData(data) {
    return data.map(item => this.languageFromData(item));
  }

  static dataFromLanguagesList(languages) {
    return languages.map(item => this.dataFromLanguage(item));
  }

  static immutableDataFromLanguagesList(languages) {
    return List(this.dataFromLanguagesList(languages))
      .map(item => Map(item));
  }
}
