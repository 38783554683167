import {
    TASK_SELECTED,
    TASK_SELECTED_CLOSE,
} from './types'

export const reducer = (state = null, action) => {
    switch (action.type) {
        case TASK_SELECTED:
            return action.payload;
        case TASK_SELECTED_CLOSE:
            return null;
        default:
            return state;
    }
};
