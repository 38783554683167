import React from 'react';
import '../../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import { asyncComponentDisplay } from '../router/asyncComponentDisplay';
import {
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import { AppRoute } from '../router/AppRoute'
import { LoginLayout } from '../layout/LoginLayout';
import { MainLayout } from '../layout/MainLayout';
import { permissionsForPath } from '../../utils/permissionsUtil';
import { mockUser } from "../../repository/mocks";



function App() {
    // mockUser();

    return (
      <Router>
          <Switch>
              <AppRoute
                  exact
                  layout={LoginLayout}
                  path="/login"
                  component={asyncComponentDisplay(() => import('../scene/auth/LoginPage'), c => c.LoginPage)}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/"
                  component={asyncComponentDisplay(() => import('../scene/task/TaskPage'), c => c.TaskPage)}
                  permissions={permissionsForPath('/')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/mytasks"
                  component={asyncComponentDisplay(() => import('../scene/task/TaskPage'), c => c.TaskPage)}
                  permissions={permissionsForPath('/mytasks')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/task/add"
                  component={asyncComponentDisplay(() => import('../scene/task/AddOrEditTaskPage'), c => c.AddOrEditTaskPage)}
                  permissions={permissionsForPath('/task/add')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/task/edit"
                  component={asyncComponentDisplay(() => import('../scene/task/AddOrEditTaskPage'), c => c.AddOrEditTaskPage)}
                  permissions={permissionsForPath('/task/edit')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/users"
                  component={asyncComponentDisplay(() => import('../scene/user/UserPage'), c => c.UserPage)}
                  permissions={permissionsForPath('/users')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/users/add"
                  component={asyncComponentDisplay(() => import('../scene/user/AddOrEditUserPage'), c => c.AddOrEditUserPage)}
                  permissions={permissionsForPath('/users/add')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/users/edit"
                  component={asyncComponentDisplay(() => import('../scene/user/AddOrEditUserPage'), c => c.AddOrEditUserPage)}
                  permissions={permissionsForPath('/users/edit')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/companies"
                  component={asyncComponentDisplay(() => import('../scene/company/CompanyPage'), c => c.CompanyPage)}
                  permissions={permissionsForPath('/companies')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/company/accounts"
                  component={asyncComponentDisplay(() => import('../scene/user/UserPage'), c => c.UserPage)}
                  permissions={permissionsForPath('/company/accounts')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/company/add"
                  component={asyncComponentDisplay(() => import('../scene/company/AddOrEditCompanyPage'), c => c.AddOrEditCompanyPage)}
                  permissions={permissionsForPath('/company/add')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/company/edit"
                  component={asyncComponentDisplay(() => import('../scene/company/AddOrEditCompanyPage'), c => c.AddOrEditCompanyPage)}
                  permissions={permissionsForPath('/company/edit')}
              />
              <AppRoute
                  exact
                  layout={MainLayout}
                  path="/statistics"
                  component={asyncComponentDisplay(() => import('../scene/statistics/StatisticsPage'), c => c.StatisticsPage)}
                  permissions={permissionsForPath('/statistics')}
              />
              <AppRoute
                  exact
                  path="/logout"
                  component={asyncComponentDisplay(() => import('../scene/auth/Logout'), c => c.Logout)}
              />
          </Switch>
      </Router>
  );
}

export default App;
