import React, { Fragment } from 'react';
import {InfoContainer} from '../../ui/component/info/container/InfoContainer';

export function LoginLayout(props) {
    return (
        <Fragment>
            <InfoContainer/>
            {props.children}
        </Fragment>
    )
}
