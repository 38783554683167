const PENDING = 'PENDING';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

export const reduceGetList = (type, state) => {
    if(type.includes(PENDING)) {
        return {
            loading: true
        };
    }
    if(type.includes(SUCCESS)) {
        return {
            loading: false,
            error: null,
            list: state.payload
        };
    }
    if(type.includes(FAIL)) {
        return {
            loading: false,
            error: state.payload.error,
            list: null
        };
    }
    return state;
};

export const reduceGetWithoutState = (type, action) => {
    if(type.includes(PENDING)) {
        return {
            loading: true
        };
    }
    if(type.includes(SUCCESS)) {
        return {
            loading: false,
            error: null,
            data: action.payload
        };
    }
    if(type.includes(FAIL)) {
        return {
            loading: false,
            error: action.payload.error,
            data: null
        };
    }
    return {};
};
