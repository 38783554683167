import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { gb } from './i18n/lang/gb';
import { pl } from './i18n/lang/pl';
import { ru } from './i18n/lang/ru';

i18n
    .use(initReactI18next)
    .use(LngDetector)
    .init({
        resources: {
            en: { translations: gb},
            pl: { translations: pl},
            ru: { translations: ru}
        },
        ns: ['translations'],
        defaultNS: 'translations',
        fallbackLng: 'en',
        debug: false,
    });

export default i18n;
