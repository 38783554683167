export const GET_TASKS_PENDING = 'GET_TASKS_PENDING';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL';

export const GET_TASK_DETAILS_PENDING = 'GET_TASK_DETAILS_PENDING';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';
export const GET_TASK_DETAILS_FAIL = 'GET_TASK_DETAILS_FAIL';

export const ASSIGN_TASK_SUCCESS = 'ASSIGN_TASK_SUCCESS';
export const ASSIGN_TASK_FAIL = 'ASSIGN_TASK_FAIL';

export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAIL = 'CHANGE_STATUS_FAIL';

export const GET_MY_TASKS = 'GET_MY_TASKS';

export const TASK_SELECTED = 'TASK_SELECTED';
export const TASK_SELECTED_CLOSE = 'TASK_SELECTED_CLOSE';

export const GET_TASK_HISTORY_PENDING = 'GET_TASK_HISTORY_PENDING';
export const GET_TASK_HISTORY_SUCCESS = 'GET_TASK_HISTORY_SUCCESS';
export const GET_TASK_HISTORY_FAIL = 'GET_TASK_HISTORY_FAIL';

export const CLEAR_NOTIFICATIONS_PENDING = 'CLEAR_NOTIFICATIONS_PENDING';
export const CLEAR_NOTIFICATIONS_SUCCESS = 'CLEAR_NOTIFICATIONS_SUCCESS';
export const CLEAR_NOTIFICATIONS_FAIL = 'CLEAR_NOTIFICATIONS_FAIL';


// GENERATED DYNAMICALLY FROM TYPE in actions.js
export const GET_INTERNAL_TASK_COMMENT_PENDING = 'GET_INTERNAL_TASK_COMMENT_PENDING';
export const GET_INTERNAL_TASK_COMMENT_SUCCESS = 'GET_INTERNAL_TASK_COMMENT_SUCCESS';
export const GET_INTERNAL_TASK_COMMENT_FAIL = 'GET_INTERNAL_TASK_COMMENT_FAIL';
export const GET_EXTERNAL_TASK_COMMENT_PENDING = 'GET_EXTERNAL_TASK_COMMENT_PENDING';
export const GET_EXTERNAL_TASK_COMMENT_SUCCESS = 'GET_EXTERNAL_TASK_COMMENT_SUCCESS';
export const GET_EXTERNAL_TASK_COMMENT_FAIL = 'GET_EXTERNAL_TASK_COMMENT_FAIL';

export const PUT_INTERNAL_TASK_COMMENT_PENDING = 'PUT_INTERNAL_TASK_COMMENT_PENDING';
export const PUT_INTERNAL_TASK_COMMENT_SUCCESS = 'PUT_INTERNAL_TASK_COMMENT_SUCCESS';
export const PUT_INTERNAL_TASK_COMMENT_FAIL = 'PUT_INTERNAL_TASK_COMMENT_FAIL';
export const PUT_EXTERNAL_TASK_COMMENT_PENDING = 'PUT_EXTERNAL_TASK_COMMENT_PENDING';
export const PUT_EXTERNAL_TASK_COMMENT_SUCCESS = 'PUT_EXTERNAL_TASK_COMMENT_SUCCESS';
export const PUT_EXTERNAL_TASK_COMMENT_FAIL = 'PUT_EXTERNAL_TASK_COMMENT_FAIL';
