import React, {Fragment, PureComponent} from 'react';
import { Message } from 'semantic-ui-react'
import {LocalStorage} from '../../../../repository/localStorage/LocalStorage';

export class Info extends PureComponent {
    componentWillUnmount() {
        this.props.clearInfo();
    }

    componentDidUpdate() {
        const { info: { message, redirect } } = this.props;
        if (message) {
            // info is not empty - clear
            setTimeout(() => this.props.clearInfo(), 4000);
        }
        if (redirect) {
            // invalid token - redirect to login page
            LocalStorage.clear();
            window.location.href='/login';
        }
    }

    renderMessageContent = () => {
        const { info: { message } } = this.props;
        const displayMsg = typeof message === 'string' ? message : 'Serwer error';
        return <Message.Header>{displayMsg}</Message.Header>
    };

    renderMessageBox = () => {
        const { info } = this.props;
        if (info && info.message) {
            return (
                <Message
                    style={{zIndex: '2050', position: 'absolute', top:'5%', width: '100%'}}
                    positive={!info.error}
                    negative={info.error}
                >
                    {this.renderMessageContent()}
                </Message>
            )
        }
    };

    render() {
        return (
            <Fragment>
                {this.renderMessageBox()}
            </Fragment>
        )
    }
}
