import {UserRepository} from '../repository/localStorage/UserRepository';
import {AuthRepository} from '../repository/localStorage/AuthRepository';

export const taskList = {
    list: [
        {
            'id': 6,
            'title': '99ce6a5383814ad48',
            'description': 'description 0',
            'status': 'TO_DO',
            'companyName': 'ZeroPOL',
            'assignedUser': null,
            'plannedDate': '2020-03-16T19:14:57.101363',
            'creationDate': '2020-03-11T19:14:57.103456',
            'commented': true
        }
    ]
};

export const mockUser = () => {
    const currentUser = {
        name: 'mockName',
        roles: ['ADMIN']
    };
    UserRepository.saveCurrentUser(currentUser);
    AuthRepository.saveAccessToken('token12345');
};

export const taskHistory = {
    data: [
        {
            'field': 'Data planowanego przybycia',
            'oldValue': '11.03.2020 09:16',
            'newValue': '11.05.2020 10:16',
            'changeDate': '11.03.2020 16:02'
        },
        {
            'field': 'Opis',
            'oldValue': 'Test',
            'newValue': 'Poprawiona wiadomość testowa',
            'changeDate': '11.03.2020 11:16'
        },
        {
            'field': 'Załącznik',
            'oldValue': '1 plik (SAD1234)',
            'newValue': '2 pliki (SAD1234, SAD9999)',
            'changeDate': '11.03.2020 11:10'
        },
    ]
};