import { createAction } from 'redux-actions';
import {
    ADD_INFO,
    CLEAR_INFO
} from './types'

export const addInfo = createAction(ADD_INFO, (data) => {
    if (typeof data === 'string') {
        return {
            message: data,
            error: false,
        }
    } else if (data && data.data && data.status) {
        const msg = typeof data.data.message === 'string' ? data.data.message : data.data.message.errorDescription;
        return {
            message: msg,
            error: data.status.toString().charAt(0) !== 2,
            redirect: data.status === 403
        }
    } else {
        return {
            message: data,
            error: true,
        }
    }
});

export const clearInfo = createAction(CLEAR_INFO);
