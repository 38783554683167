import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import preval from 'preval.macro'
import MenuHead from '../component/MenuHead';
import { AuthRepository } from '../../repository/localStorage/AuthRepository';
import { InfoContainer } from '../component/info/container/InfoContainer';
import {LanguageSelector} from '../../i18n/LanguageSelector';


export function MainLayout(props) {
    if (AuthRepository.readAccessToken() && !window.location.href.includes('login')) {
        return (
            <Fragment>
                <MenuHead content={props.children} path={props.path} />
                <InfoContainer/>
                <p style={{ marginTop: '10px', textAlign: 'center' }}>
                    <LanguageSelector/>
                </p>
                <p style={{ color: 'lightgray', textAlign: 'center'}}>
                    build time: {preval`module.exports = new Date().toLocaleString()`}.
                </p>
            </Fragment>
        )
    } else {
        return <Redirect from='/' to='/login'/>;
    }
}
