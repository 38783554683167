export const user = {
    user_username: 'Nazwa użytkownika',
    user_role: 'Rola',
    user_add_user: 'Dodaj użytkownika',
    user_add_to_company: 'Dodaj konto firmowe',
    user_password: 'Hasło',
    user_password_confirm: 'Potwierdź hasło',
    user_phone_number: 'Numer telefonu',
    user_loading_list: 'Ładowanie listy użytkowników',
    user_title_change_password: 'Zmień hasło',

    user_added: 'Dodano użytkownika',
    user_no_users: 'Brak',
    user_validation_username_required: 'Nazwa użytkownika jest wymagana',
    user_validation_password_same: 'Wprowadź to samo hasło dwa razy',
    user_validation_password_complexity: 'Hasło powinno zawierać przynajmniej 8 znaków, jedną małą literę, jedną wielką, jedną cyfrę, jeden znak specjalny',
    user_validation_password_different: 'Podane hasła są różne',
    user_validation_role_required: 'Wybierz rolę użytkownika',
    user_validation_company_required: 'Wybierz firmę do której użytkownik powinien zostać przypisany',
    user_validation_username_email: 'Nazwa konta powinna być adresem email',
}
