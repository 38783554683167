export const reducer = (state = {}, action) => {
    switch (action.type) {
        case 'ADD_INFO':
            return Object.assign({}, state, {
                message: action.payload.message,
                error: action.payload.error,
                redirect: action.payload.redirect,
            });
        case 'CLEAR_INFO':
            return {};
        default:
            return state;
    }
};
