export const task = {
    view_closed: 'Przeglądaj zamknięte',
    task_list_filter_from: 'od',
    task_list_filter_to: 'do',
    date_input_search: 'Szukaj',
    task_list_filter_reset: 'reset',
    task_title: 'Tytuł',
    task_company_name: 'Firma',
    task_status: 'Status',
    task_assigned_user: 'Przypisany użytkownik',
    task_created_date: 'Data utworzenia',
    task_planned_date: 'Planowana data',

    task_details_header: 'Szczegóły',
    task_last_modification: 'Ostatnia modyfikacja',
    task_download_all: 'Pobierz wszystkie',
    task_comments: 'Komentarze',
    task_employee_comment: 'Komentarz pracownika (wewnętrzny)',
    task_add_comment: 'Dodaj komentarz',
    task_edit_history: 'Historia edycji',
    task_detail_attribute: 'Pole',
    task_attribute_before: 'Przed',
    task_attribute_after: 'Po',
    task_change_date: 'Data zmiany',
    task_add: 'Dodaj zlecenie',
    task_edit: 'Edytuj zlecenie',
    task_created_by: 'Utworzone przez',
    task_details_planned_date: 'Planowana data przybycia',
    task_description: 'Opis',
    task_status_action_take: 'Podejmij',
    task_status_action_close: 'Zamknij',
    task_status_action_to_do: 'Cofinij do zrobienia',
    task_assign_button: 'Weź zgłoszenie',
    task_status_change: 'Zmień status',

    task_files: 'Pliki',
    task_saved: 'Zlecenie zapisane',
    task_validation_title_required: 'Tytuł zlecenia jest wymagany',
    task_validation_title_constrain: 'Zbyt dużo znaków',
    task_validation_file_constrain: 'Jeden z załączonych plików jest zbyt duży',
    task_validation_files_constrain: 'Wybrane pliki są zbyt duże, obecnie: ',

    color_legend_show: 'Pokaż legendę',
    color_legend_hide: 'Ukryj legendę',
    color_legend_INTERNAL: 'Komentarz wewnętrzny',
    color_legend_EXTERNAL: 'Komentarz zewnętrzny',
    color_legend_NEW_FILES: 'Nowe pliki',
}
