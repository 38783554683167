import { login } from './login';
import { menu } from './menu';
import { task } from './task';
import { table } from './table';
import { user } from './user';
import { company } from './company';
import { statistics } from './statistics';

export const pl = {
    language: 'Język',
    action: 'Akcje',
    no_files: 'Brak plików',
    cancel: 'Anuluj',
    save: 'Zapisz',
    saving: 'Zapisywanie',
    edit: 'Edytuj',
    client: 'Klient',
    employee: 'Pracownik',
    admin: 'Administrator',
    saved_changes: 'Zapisano zmiany',
    to_do: 'Do zrobienia',
    in_progress: 'W trakcie',
    done: 'Zakończone',
    ...login,
    ...menu,
    ...task,
    ...table,
    ...user,
    ...company,
    ...statistics,
}
