export const reducer = (state = {}, action) => {
    switch (action.type) {
        case 'USER_LOGIN':
            return Object.assign({}, state, {
                userLogin: action.payload
            });
        case 'USER_LOGOUT':
            return Object.assign({}, state, {
                userLogout: action.payload
            });
        default:
            return state;
    }
};
