import React, { PureComponent } from 'react';

export function asyncComponentDisplay(importFn, transformFn) {
  return class extends PureComponent {
    state = {
      Component: null,
    };

    componentDidMount() {
      importFn()
        .then((Component) => {
          if (transformFn === undefined) this.setState({ Component });
          this.setState({ Component: transformFn(Component) });
        });
    }

    render() {
      const { Component } = this.state;

      if (Component === null) return null;
      return <Component {...this.props} />;
    }
  };
}
