import { LanguageMapper } from './LanguageMapper';

export class I18nRepositoryMemory {
  static _langs = [
    {
      code: 'pl',
      name: 'Polski',
    },
    {
      code: 'gb',
      name: 'English',
    },
    // {
    //   code: 'ru',
    //   name: 'Русский',
    // },
  ];

  static getAllLanguages() {
    return LanguageMapper.languagesListFromData(this._langs);
  }

  static getDefaultLanguage() {
    const defaultLang = this._langs[1];

    return LanguageMapper.languageFromData(defaultLang);
  }

  static getLanguageByCode(langCode) {
    for (let i = 0; i < this._langs.length; i++) {
      if (this._langs[i].code === langCode) {
        return LanguageMapper.languageFromData(this._langs[i]);
      }
    }

    throw new Error('Invalid language');
  }
}
