export const task = {
    view_closed: 'Обзор закрыт',
    task_list_filter_from: 'oт',
    task_list_filter_to: 'До',
    date_input_search: 'Найти',
    task_list_filter_reset: 'Сброс',
    task_title: 'Заголовок',
    task_company_name: 'Компания',
    task_status: 'Статус',
    task_assigned_user: 'Назначенный пользователь',
    task_created_date: 'Дата создания',
    task_planned_date: 'Планированная дата',

    task_details_header: 'Детали',
    task_last_modification: 'Последняя модификация',
    task_download_all: 'Скачать все',
    task_comments: 'Коментарии',
    task_add_comment: 'Добавить комментарий',
    task_employee_comment: 'Комментарий сотрудника (внутренний)',
    task_edit_history: 'История издания',
    task_detail_attribute: 'Поле',
    task_attribute_before: 'Перед',
    task_attribute_after: 'После',
    task_change_date: 'Дата изменения',
    task_add: 'Добавить заказ',
    task_edit: 'Изменить заказ',
    task_created_by: 'Создано',
    task_details_planned_date: 'Планированная дата',
    task_description: 'Описание',
    task_status_action_take: 'взять',
    task_status_action_close: 'Конец',
    task_status_action_to_do: 'сделать',
    task_assign_button: 'Назначить мне',
    task_status_change: 'Изменить статус',

    task_files: 'файлы',
    task_saved: 'Задача сохранена',
    task_validation_title_required: 'Требуемая должность',
    task_validation_title_constrain: 'Слишком много персонажей',
    task_validation_file_constrain: 'Один из файлов слишком велик',
    task_validation_files_constrain: 'Выбранные файлы слишком велики, в настоящее время:',

    color_legend_show: 'Показать легенду',
    color_legend_hide: 'Скрыть легенду',
    color_legend_INTERNAL: 'Внутренний комментарий',
    color_legend_EXTERNAL: 'Внешний комментарий',
    color_legend_NEW_FILES: 'Новые файлы',
}
