import React, { PureComponent, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserRepository } from '../../repository/localStorage/UserRepository';

export class AppRoute extends PureComponent {
  state = {
      cuAuthority: UserRepository.getCurrentUserAuthority(),
  };

  componentDidUpdate(prevProps) {
    const { path } = this.props;

    if (prevProps.path !== path) {
      this.setState({
        cuAuthority: UserRepository.getCurrentUserAuthority(),
      });
    }
  }

  userHasPermissions = () => {
    const { permissions } = this.props;
    const { cuAuthority } = this.state;

    if (!permissions) {
      return true;
    } else {
      return !!permissions.filter(value => cuAuthority === value).length;
    }
  };

  renderRoute = (matchProps) => {
    const {
      component: Scene,
      layout: Layout,
      path
    } = this.props;

    if (Scene === undefined) return null;
    if (Layout === undefined) return <Scene {...matchProps} />;
    return (
      <Layout path={path}>
        <Scene {...matchProps} />
      </Layout>
    );
  };

  render() {
    const props = { ...this.props };
    const { cuAuthority } = this.state;

    if (props.component !== undefined) delete props.component;
    if (props.layout !== undefined) delete props.layout;
    return (
      <Fragment>
        { props.path !== '/login' && !cuAuthority && <Redirect from={props.path} to={'/login'} /> }
        { props.path !== '/login' && !this.userHasPermissions() && <Redirect from={props.path} to={'/denied'} /> }
        <Route {...props} render={this.renderRoute} />
      </Fragment>
    );
  }
}
