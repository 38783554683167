export class LocalStorage {
  static key = '_tm';

  static create(key, payload) {
    const data = this._getData();

    data[key] = payload;

    this._saveData(data);
  }

  static read(key) {
    const data = this._getData();

    return data[key] || null;
  }

  static update(key, payload) {
    const data = this._getData();

    data[key] = payload;

    this._saveData(data);
  }

  static delete(key) {
    const data = this._getData();

    delete data[key];

    this._saveData(data);
  }

  static has(key) {
    const data = this._getData();

    return data[key] !== undefined;
  }

  static clear() {
    localStorage.removeItem(this.key);
  }

  static _encode(data) {
    return JSON.stringify(data);
  }

  static _decode(data) {
    if (typeof data !== 'string') return {};

    try {
      return JSON.parse(data);
    } catch (e) {
      return {};
    }
  }

  static _getData() {
    return this._decode(localStorage.getItem(this.key));
  }

  static _saveData(data) {
    localStorage.setItem(this.key, this._encode(data));
  }
}
