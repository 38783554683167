import {
    GET_TASK_HISTORY_PENDING,
    GET_TASK_HISTORY_SUCCESS,
    GET_TASK_HISTORY_FAIL
} from './types'

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_TASK_HISTORY_PENDING:
            return {
                ...state,
                loading: true
            };
        case GET_TASK_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };
        case GET_TASK_HISTORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: null
            };
        default:
            return state;
    }
};
