export const user = {
    user_username: 'Имя пользователя',
    user_role: 'Роль',
    user_add_user: 'Добавить бизнес аккаунт',
    user_add_to_company: 'Dodaj konto firmowe',
    user_password: 'Пароль',
    user_password_confirm: 'Подтвердить пароль',
    user_phone_number: 'Номер телефона',
    user_loading_list: 'Загрузка списка пользователей',
    user_title_change_password: 'Изменить пароль',

    user_added: 'Пользователь добавлен',
    user_no_users: 'Нет пользователей',
    user_validation_username_required: 'Имя пользователя требуется',
    user_validation_password_same: 'Введите один и тот же пароль дважды',
    user_validation_password_complexity: 'Пароль должен содержать не менее 8 символов, одну строчную букву, одну заглавную букву, один специальный символ',
    user_validation_password_different: 'Введенные пароли разные',
    user_validation_role_required: 'Роль обязательна',
    user_validation_company_required: 'Выберите компанию, которой должен быть назначен пользователь',
    user_validation_username_email: 'Имя пользователя должно быть адресом электронной почты',
}
