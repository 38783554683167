export const menu = {
    menu_all_tasks: 'Все заказы',
    menu_my_tasks: 'Мои заказы',
    menu_add_task: 'Добавить заказ',
    menu_users: 'Пользователи',
    menu_company_users: 'Учетные записи компании',
    menu_companies: 'Компании',
    menu_statistics: 'Статистика',
    menu_logout: 'Выход',
}
