export const user = {
    user_username: 'Username',
    user_role: 'Role',
    user_add_user: 'Add user account',
    user_add_to_company: 'Add company user account',
    user_password: 'Password',
    user_password_confirm: 'Confirm password',
    user_phone_number: 'Phone number',
    user_loading_list: 'Loading user list',
    user_title_change_password: 'Change password',

    user_added: 'User added',
    user_no_users: 'No users',
    user_validation_username_required: 'Username is required',
    user_validation_password_same: 'Enter same password twice',
    user_validation_password_complexity: 'Password should contain at least 8 characters, one lower case letter, one upper case, one special sign',
    user_validation_password_different: 'Entered passwords are different',
    user_validation_role_required: 'Role is required',
    user_validation_company_required: 'Choose company to which user should be assigned',
    user_validation_username_email: 'Username should be an email address',
}
