import { LocalStorage } from './LocalStorage';

export class UserRepository {
  static LOCAL_STORAGE_KEY = 'currentUser';

  static saveCurrentUser(currentUserData) {
    LocalStorage.create(UserRepository.LOCAL_STORAGE_KEY, currentUserData);
  }

  static getCurrentUserAuthority() {
    const currentUser = this.getCurrentUser();
    return currentUser ? currentUser.authority : null;
  }

  static getCurrentUserName() {
    const currentUser = this.getCurrentUser();
    return currentUser ? currentUser.username : null;
  }

  static getCurrentUser() {
    return LocalStorage.read(UserRepository.LOCAL_STORAGE_KEY);
  }
}
