import React, { Fragment, useState } from 'react';
import {useTranslation} from 'react-i18next';
import { Menu, Responsive, Sidebar, Icon } from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {UserRepository} from '../../repository/localStorage/UserRepository';
import { permissionsForPath } from '../../utils/permissionsUtil';
import PropTypes from 'prop-types';

const leftLinks = [
    {
        name: 'taskList',
        displayName: 'menu_all_tasks',
        to: '/',
        permissions: permissionsForPath('/')
    },
    {
        name: 'mytasks',
        displayName: 'menu_my_tasks',
        to: '/mytasks',
        permissions: permissionsForPath('/mytasks')
    },
    {
        name: 'addTask',
        displayName: 'menu_add_task',
        to: '/task/add',
        permissions: permissionsForPath('/task/add')
    },
    {
        name: 'userList',
        displayName: 'menu_users',
        to: '/users',
        permissions: permissionsForPath('/users')
    },
    {
        name: 'companyUserList',
        displayName: 'menu_company_users',
        to: '/company/accounts',
        permissions: permissionsForPath('/company/accounts')
    },
    {
        name: 'companiesList',
        displayName: 'menu_companies',
        to: '/companies',
        permissions: permissionsForPath('/companies')
    },
    {
        name: 'statistics',
        displayName: 'menu_statistics',
        to: '/statistics',
        permissions: permissionsForPath('/statistics')
    }
];

const rightLinks = [
    {
        name: 'logout',
        displayName: 'menu_logout',
        to: '/logout',
    }
]

const NavBarMobile = ({content, leftMenuItems, rightMenuItems, onPusherClick, onToggle, visible}) => (
    <Sidebar.Pushable>
        <Sidebar
            as={Menu}
            direction="top"
            animation="overlay"
            icon="labeled"
            inverted
            vertical
            visible={visible}
        >
            {leftMenuItems}
        </Sidebar>
        <Sidebar.Pusher
            dimmed={visible}
            onClick={onPusherClick}
            style={{ minHeight: '100vh' }}
        >
            <Menu inverted>
                <Menu.Item onClick={onToggle}>
                    <Icon name="sidebar" />
                </Menu.Item>
                {rightMenuItems}
            </Menu>
            {content}
        </Sidebar.Pusher>
    </Sidebar.Pushable>
);
NavBarMobile.propTypes = {
    content: PropTypes.object.isRequired,
    leftMenuItems: PropTypes.any.isRequired,
    rightMenuItems: PropTypes.any.isRequired,
    onPusherClick: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};

const NavBarDesktop = ({leftMenuItems, rightMenuItems}) => {
    return (
        <Menu stackable inverted style={{background:'#3e556d'}}>
            {leftMenuItems}
            {rightMenuItems}
        </Menu>
    );
}
NavBarDesktop.propTypes = {
    leftMenuItems: PropTypes.any.isRequired,
    rightMenuItems: PropTypes.any.isRequired,
};

export default function MenuHead(props) {
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    const cuAuthority = UserRepository.getCurrentUserAuthority();

    const handlePusher = () => {
        if (visible) setVisible(false);
    };

    const handleToggle = () => setVisible(!visible);

    const renderLeftItems = (leftLinks) => {
        return leftLinks.map((link, idx) => {
            const navLink = (
                <Menu.Item
                    name={link.name}
                    active={props.path === link.to}
                    as={Link}
                    to={link.to}
                    key={`menu_link_${idx}`}
                >
                    {t(link.displayName)}
                </Menu.Item>
            );
            // no permissions described
            if(!link.permissions) return navLink;
            // permissions match
            if(link.permissions && link.permissions.includes(cuAuthority)) return navLink;
            // no permission
            return null;
        });
    };

    const renderRightItems = (rightLinks) => {
        return (
            <Menu.Menu position='right'>
                <Menu.Item>
                    <Icon name="user outline"/>
                    {UserRepository.getCurrentUserName()}
                </Menu.Item>
                {rightLinks.map((link, idx) => (
                    <Menu.Item
                        key={`right-menu-${idx}`}
                        name={t(link.displayName)}
                        as={Link}
                        to={link.to}
                    />
                ))}
            </Menu.Menu>
        )}

    const { content } = props;
    return (
        <Fragment>
            <Responsive {...Responsive.onlyMobile}>
                <NavBarMobile
                    content={content}
                    leftMenuItems={renderLeftItems(leftLinks)}
                    onPusherClick={handlePusher}
                    onToggle={handleToggle}
                    rightMenuItems={renderRightItems(rightLinks)}
                    visible={visible}
                />
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <NavBarDesktop
                    leftMenuItems={renderLeftItems(leftLinks)}
                    rightMenuItems={renderRightItems(rightLinks)}
                />
                {content}
            </Responsive>
        </Fragment>
    );
}

MenuHead.propTypes = {
    content: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
