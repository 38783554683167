import {UserRepository} from '../repository/localStorage/UserRepository';

const permissionConfig = {
    '/': ['ADMIN', 'EMPLOYEE', 'CLIENT'],
    '/mytasks': ['EMPLOYEE'],
    '/task/add': ['CLIENT'],
    '/task/edit': ['CLIENT'],
    '/users': ['ADMIN'],
    '/users/add': ['ADMIN', 'CLIENT'],
    '/users/edit': ['ADMIN'],
    '/companies': ['ADMIN'],
    '/company/add': ['ADMIN'],
    '/company/accounts': ['CLIENT'],
    '/statistics': ['ADMIN'],
};

export const permissionsForPath = (path) => {
    return permissionConfig[path];
};

export const isUserAllowed = (allowed) => {
    const cuAuthority = UserRepository.getCurrentUserAuthority();
    return allowed.includes(cuAuthority);
};
