import {reduceGetList} from '../../../../utils/reducerUtil';
import {
    GET_COMPANIES_PENDING,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_FAIL,
} from '../types'

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COMPANIES_PENDING:
        case GET_COMPANIES_SUCCESS:
        case GET_COMPANIES_FAIL:
            return reduceGetList(action.type, action);
        default:
            return state;
    }
};
